<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">
        支援報備線上確認功能
      </h5>
      <div class="dtc-search1">
        <b-input-group prepend="姓名">
          <b-input v-model="searchSubject" @keydown.enter="getData"></b-input>
        </b-input-group>
        <b-button variant="primary" @click="getData">進行查詢</b-button>
        <b-button variant="danger" @click="clearSearch">清除條件</b-button>
      </div>
    </section>

    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headersContent"
        :key="`headersContent${i}`"
        :title="item.name"
        @click="sort(item)"
      >
        {{ item.name }}
        <span v-show="item.sortDesc === null" v-if="item.key">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false" v-if="item.key">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc" v-if="item.key">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#ffffff ;' : 'background-color: #efefef;'
      "
    >
      <div :title="i" style="padding: 25px;">
        {{ i+1 }}
      </div>

      <div :title="item.sup_id" style="padding: 25px;">
        {{ item.sup_id || "" }}
      </div>
      <div :title="item.doc_name" style="padding: 25px;">
        {{ item.doc_name || "" }}
      </div>
      <div :title="item.doc_id_no" style="padding: 25px;">
        {{ item.doc_id_no || "" }}
      </div>
      <div :title="item.cer_doc_id+item.cer_doc_no" style="padding: 25px;">
        {{ item.cer_doc_id + item.cer_doc_no || "" }}
      </div>
      <div :title="item.prd_from_bas_name" style="padding: 25px;">
        {{ item.prd_from_bas_name || "" }}
      </div>
      <div :title="123" style="padding: 25px;">
        地址
      </div>
      <div style="padding: 25px;-webkit-box-orient: horizontal;">
        <b-button
          variant="success"
          size="sm"
          @click="openModal(2, item)"
          class="mr-2"
          >檢視</b-button
        >
      </div>
      <div style="padding: 25px;">
        <b-button variant="warning" size="sm" class="mr-2" @click="approveRecord(item)" :disabled="item.status != 0">同意備查</b-button>
        <b-button variant="danger" size="sm" class="mr-2" @click="rejectRecord(item)" :disabled="item.status != 0">退回</b-button>
      </div>
      <div :title="item.status" style="padding: 25px;">
        {{ getStatusText(item.status) }}
      </div>
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
    <div>
      <b-modal id="editModal" :title="`資料編輯`" size="lg">
        <div class="modal-grid-2">
          <b-input-group prepend="案件編號" class="mt-2">
            <b-input
              v-model="modalItem.sup_id"
              style="padding-right:28px"
              disabled
            ></b-input>
          </b-input-group>
          <b-input-group prepend="案件狀態" class="mt-2">
            <b-input
              :value="getStatusText(modalItem.status)"
              style="padding-right: 28px"
              type="text"
              disabled
            ></b-input>
          </b-input-group>
          <b-input-group prepend="姓名" class="mt-2">
            <b-input
              v-model="modalItem.doc_name"
              style="padding-right:28px"
              type="text"
              min="0"
              disabled
            ></b-input>
          </b-input-group>
          <b-input-group prepend="身分證字號" class="mt-2">
            <b-input
              v-model="modalItem.doc_id_no"
              style="padding-right:28px"
              type="text"
              min="0"
              disabled
            ></b-input>
          </b-input-group>
          <b-input-group prepend="證書字號" class="mt-2">
            <b-input
            :value="modalItem.cer_doc_id+modalItem.cer_doc_no"
              style="padding-right:28px"
              type="text"
              min="0"
              disabled
            ></b-input>
          </b-input-group>
          <b-input-group prepend="申請機構名稱" class="mt-2">
            <b-input
              v-model="modalItem.bas_name"
              style="padding-right:28px"
              type="text"
              min="0"
              disabled
            ></b-input>
          </b-input-group>
          <b-input-group prepend="地址" class="mt-2">
            <b-input
              v-model="modalItem.MajorContract2Show"
              style="padding-right:28px"
              type="number"
              min="0"
              disabled
            ></b-input>
          </b-input-group>
          <b-input-group prepend="支援機構代碼" class="mt-2">
            <b-input
              v-model="modalItem.prd_from_bas_agency_id"
              style="padding-right:28px"
              type="text"
              min="0"
              disabled
            ></b-input>
          </b-input-group>
          <b-input-group prepend="支援機構名稱" class="mt-2">
            <b-input
              v-model="modalItem.prd_from_bas_name"
              style="padding-right:28px"
              disabled
            ></b-input>
          </b-input-group>
          <b-input-group prepend="醫事人員類別" class="mt-2">
            <b-input
              v-model="modalItem.ref_name"
              style="padding-right:28px"
              disabled
            ></b-input>
          </b-input-group>
          <b-input-group prepend="支援機構地址" class="mt-2">
            <b-input
              v-model="modalItem.Remark"
              style="padding-right:28px"
              disabled
            ></b-input>
          </b-input-group>
          <b-input-group prepend="支援開始時間" class="mt-2">
            <b-input
              v-model="modalItem.prd_sdate"
              style="padding-right:28px"
              disabled
            ></b-input>
          </b-input-group>
          <b-input-group prepend="支援結束時間" class="mt-2">
            <b-input
              v-model="modalItem.prd_edate"
              style="padding-right:28px"
              disabled
            ></b-input>
          </b-input-group>
          <b-input-group prepend="報備時數" class="mt-2">
            <b-input
              :value="modalItem.prd_hr"
              style="padding-right: 28px"
              type="number"
              disabled
            ></b-input>
          </b-input-group>
        </div>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="$bvModal.hide('editModal')"
              >取消</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>
  </section>
</template>

<script>
import buildQuery from "odata-query";

// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

const headersContent = [
  { name: "序", key: "", sortDesc: null },
  { name: "案件編號", key: "", sortDesc: null },
  { name: "姓名", key: "", sortDesc: null },
  { name: "身分證字號", key: "", sortDesc: null },
  { name: "證書字號", key: "", sortDesc: null },
  { name: "申請機構名稱", key: "", sortDesc: null },
  { name: "地址", key: "", sortDesc: null },
  { name: "動作", key: "", sortDesc: null },
  { name: "審核", key: "", sortDesc: null },
  { name: "狀態", key: "", sortDesc: null },
];

const rows = [10, 20, 50];

export default {
  name: "downarea",
  data() {
    return {
      //main-content
      status: 1, 
      headersContent,
      items: [],

      //footer
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      rows,
      totalCountStr: "",

      //modal
      modalItem: {
        bas_agency_id: "",
        sup_id: "",
        prd_from_bas_agency_id: "",
        prd_from_bas_name: "",
        doc_id_no: "",
        doc_name: "",
        ref_name: "",
        prd_sdate: "",
        prd_edate: "",
        prd_seq: "",
        prdu_apply_m: "",
        status: "",
      },
      modalType: 1,

      //search
      searchSubject: "",
      searchRemark: "",
      searchUnit: null,

      //searchOptions or ModalOptions

      content: "<h2></h2>",
      editorOption: {
        // some quill options
      },
      healthCodeOptions: [],
    };
  },
  components: {
    quillEditor,
    Treeselect,
  },
  computed: {
    healthCodeCheck() {
      const loginHealthCode = sessionStorage
        .getItem("darcok")
        .replace(/\"/g, "");
      const opsHpitalArr = JSON.parse(sessionStorage.getItem("opsHpital"));
      const stuChoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
      const roleHealthBureauArr = JSON.parse(
        sessionStorage.getItem("roleHealthBureau")
      );
      const roleHealthCenter = JSON.parse(
        sessionStorage.getItem("roleHealthCenter")
      );
      const allArr = [
        ...opsHpitalArr,
        ...stuChoolArr,
        ...roleHealthBureauArr,
        ...roleHealthCenter,
      ];
      return allArr.includes(loginHealthCode);
    },
  },

  methods: {

    getStatusText(status) {
      switch (status) {
        case "1":
          return "同意審核";
        case "2":
          return "未通過";
        case "0":
          return "未審核";
        default:
          return "00";
      }
    },
    clearSearch() {
      this.searchSubject = "";
      this.searchRemark = "";
      this.searchUnit = null;
      this.getData();
    },
    sort(item) {
      if (!item.key) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];

      this.headersContent.forEach((s) => {
        if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });

      this.getData();
    },
    async openModal(type, item) {
      const codeNow = sessionStorage.getItem("darcok").replace(/\"/g, "");
      this.modalType = type;
      if (type == 1) {
        this.modalItem = {
          Subject: "",
          Content: "",
          Remark: "",
          StartDate: "",
          EndDate: "",
          Creater: `${window.sessionStorage.docrkkt}`.replace(/\"/g, ""),
          CreateTime: this.$moment(new Date()).format("YYYY-MM-DD"),
          Update: `${window.sessionStorage.docrkkt}`.replace(/\"/g, ""),
          UpdateTime: this.$moment(new Date()).format("YYYY-MM-DD"),
          HealthCode: codeNow,
        };
      } else {
        this.modalItem = JSON.parse(JSON.stringify(item));
        this.modalItem.StartDate = this.$twDate(item.StartDate);
        this.modalItem.EndDate = this.$twDate(item.EndDate);
        this.modalItem.Update = `${window.sessionStorage.docrkkt}`.replace(
          /\"/g,
          ""
        );
        this.modalItem.UpdateTime = this.$moment(new Date()).format(
          "YYYY-MM-DD"
        );
      }
      this.$bvModal.show("editModal");
    },
    async saveModal() {
      const url = `api/Bulletin`;
      const obj = {
        ...this.modalItem,
      };
      obj.StartDate = this.$usDate(obj.StartDate);
      obj.EndDate = this.$usDate(obj.EndDate);
      try {
        this.modalType == 1
          ? await window.axios.post(url, obj)
          : await window.axios.put(url, obj);
        this.$bvToast.toast(
          `招生公告${this.modalType == 1 ? "新增" : "編輯"}成功`,
          {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "success",
          }
        );
        this.getData();
        this.$bvModal.hide("editModal");
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async deleteModal(item) {
      const url = `Bulletin/Delete?Id=${item.Id}`;

      try {
        await window.axios.delete(url);
        this.$bvToast.toast(`刪除成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.getData();
      } catch (e) {
        this.$bvToast.toast(`${e.response ? e.response.data : e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },

    async approveRecord(item) {
      try {
        item.status = "1";
        const url = `/api/Supportpage211`;
        const supportlisturl = `/api/Supporlist`;

        const supportlistitems = {
          sup_id: item.sup_id,
          doc_name: item.doc_name,
          doc_id_no: item.doc_id_no,
          cer_num: item.cer_doc_id + item.cer_doc_no,
          bas_agency_id: item.bas_agency_id,
          address: item.address,
          prd_from_bas_agency_id: item.prd_from_bas_agency_id,
          prd_from_bas_name: item.prd_from_bas_name,
          ref_name: item.ref_name,
          prd_sdate: item.prd_sdate,
          prd_edate: item.prd_edate,
          prd_hr: item.prd_hr,
        };

        await window.axios.put(url, item);
        console.log(supportlistitems);
        await window.axios.post(supportlisturl, supportlistitems);
        item.status = "1";
      } catch (error) {
        console.error('Failed to update status:', error);
      }
    },
    async rejectRecord(item) {
      try {
        item.status = "2";
        const url = `/api/Supportpage211`;
        await window.axios.put(url, item);

        item.status = "2";
      }catch (error) {
        console.error('Failed to update status:', error);
      }
    },

    async getData() {
      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = this.orderBy;
      const obj = { top, skip, orderBy };
      let qs211 = buildQuery(obj);
      let qs107 = buildQuery(obj);
      let qs102 = buildQuery(obj);
      let bQs = false;

      try {
        if (this.searchSubject) {
          qs211 = this.$equalSubstringofFilter("doc_name", qs211, this.searchSubject);
          bQs = true;
        }
        let { Items: supportpageItems, Count: supportpageCount } = await window.axios.get(`/api/Supportpage211${qs211}`);
        let { Items: supportpageItems107, Count: supportpageCount107 } = await window.axios.get(`/api/Supportpage107${qs107}`);
        let { Items: supportpageItems102, Count: supportpageCount102 } = await window.axios.get(`/api/Supportpage102${qs102}`);
        let integratedItems = [];

        // 遍历第一张表的数据
        supportpageItems.forEach((supportpageItem) => {
        // 找到第二张表中匹配的数据
        const matchingData = supportpageItems107.find((item107) => item107.sup_id === supportpageItem.sup_id);
        console.log(matchingData);
        // 如果找到匹配的数据，进行整合
        if (matchingData) {
          // 将两个表的数据整合到新的对象中
          const matchingData102 = supportpageItems102.find((item102) => item102.doc_id_no === supportpageItem.doc_id_no);
          const integratedItem = {
            sup_id: supportpageItem.sup_id,
            doc_name: supportpageItem.doc_name,
            doc_id_no: supportpageItem.doc_id_no,
            prd_from_bas_agency_id: supportpageItem.prd_from_bas_agency_id,
            prd_from_bas_name: supportpageItem.prd_from_bas_name,
            bas_agency_id: supportpageItem.bas_agency_id,
            bas_name: matchingData.bas_name,
            ref_name: supportpageItem.ref_name,
            prd_sdate: supportpageItem.prd_sdate,
            prd_edate: supportpageItem.prd_edate,
            prd_seq: supportpageItem.prd_seq,
            prdu_apply_m: supportpageItem.prdu_apply_m,
            status: supportpageItem.status,
            prd_hr: matchingData.prd_hr,
            cer_doc_id: matchingData102 ? matchingData102.cer_doc_id : null,
            cer_doc_no: matchingData102 ? matchingData102.cer_doc_no : null,
          };
          // 将整合后的对象添加到新数组中
          integratedItems.push(integratedItem);
        }
      });

  // 将整合后的数组赋值给组件的数据属性
  this.items = integratedItems;
  this.rowCount = integratedItems.length;
  this.totalCountStr = `共${integratedItems.length} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getAllHealthCodeOption() {
      const url1 = `SysRole/GetRoleInstitutions?no=Admin`;
      const url2 = `SysRole/GetRoleInstitutions?no=Hospital1`;
      const url3 = `SysRole/GetRoleInstitutions?no=Supervisor`;
      const url4 = `SysRole/GetRoleInstitutions?no=user1`;
      const url5 = `SysRole/GetRoleInstitutions?no=user3`;
      const url6 = `SysRole/GetRoleInstitutions?no=user4`;

      const arr1 = await window.axios.get(url1);
      const arr2 = await window.axios.get(url2);
      const arr3 = await window.axios.get(url3);
      const arr4 = await window.axios.get(url4);
      const arr5 = await window.axios.get(url5);
      const arr6 = await window.axios.get(url6);
      let allArr = [...arr1, ...arr2, ...arr3, ...arr4, ...arr5, ...arr6];
      let allArr1 = allArr.map((s) => ({
        id: s.No,
        label: `${s.Name}【${s.No}】`,
        name: s.Name,
      }));

      const uniqueArr1 = this.getUniqueListBy(allArr1, "id");

      this.healthCodeOptions = JSON.parse(JSON.stringify(uniqueArr1));
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },
  },
  async mounted() {
    await this.getAllHealthCodeOption();
    await this.getData();
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  padding: 12px;
  width: fit-content;
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 120px 170px 120px 230px 130px 230px 190px 120px 200px 130px;
  margin-right: 4px;
  grid-auto-flow: column;
  text-align: center;
  padding-right: 0px;
  border-left: 1px solid #dee2e5;
  border-right: 1px solid #dee2e5;
  border-top: 1px solid #dee2e5;
  color: #212529;
  > div {
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
    color: #212529;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 16px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  > div:first-child {
    border-left: 1px solid #cccccc;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 4px;
    // padding-top: 7px;
  }
}

.my-dark {
  > div {
    background: #333;
    color: white;
  }
}

.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 32px;
  margin-bottom: 12px;
  grid-template-columns: repeat(6, max-content);
  grid-gap: 12px;
  padding-left: 10px;
  > * {
    max-height: 44px;
  }
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 125px;
  background: #0379fd;
  color: #fff;
}

.search-block {
  //   height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }

  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
::v-deep .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
.my-light {
  > div {
    background: black;
    color: black;
  }
}
.modal-grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
}
::v-deep .ql-container {
  height: 460px;
}
</style>
