<template>
  <section class="dtc-main-section">
    <vue-tabs v-model="tabName">
      <v-tab
        v-for="(item, i) in titles"
        :title="item.title"
        :key="item.comps + i"
      >
        <component
          v-if="tabName === item.title"
          :is="item.comps"
          :tabName="item.comps"
        />
      </v-tab>
    </vue-tabs>
  </section>
</template>

<script>
import { store } from "@/store/global.js";
import { VueTabs, VTab } from "vue-nav-tabs";
import "vue-nav-tabs/themes/vue-tabs.css";

import Support_page from "./Support_page";


export default {
  name: "totalSpendTabsVue",
  data() {
    return {
      showTab1: false,
      show: true,
      tabName: "",
      allUserRight: [],
      userRights: [],
      mainTitle: "「衛生所缺額管理」",
      titles: [
        {
          title: "支援報備線上確認功能",
          comps: "Support_page",
        },
      ],
    };
  },
  computed: {
    nowTab() {
      return store.nowTab;
    },
  },
  components: {
    VueTabs,
    VTab,
    Support_page,
  },
  methods: {},
  created() {},
  mounted() {},
  beforeMount() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.dtc-main-section {
  width: fit-content;
  //   height: 100vh;
  //   overflow-x: hidden;
}
iframe {
  width: 100vw;
  height: clamp(60vh, 90vh, 100vh);
}
</style>
